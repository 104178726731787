import React from 'react';

// const BASE_URL = import.meta.env.BASE_URL || "/images/";
const BASE_URL = '/images/';

type ImgProps = React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
> &
    Partial<{
        className: string;
        src: string;
        alt: string;
        isStatic: boolean;
        width: number;
        height: number;
    }>;

const Img: React.FC<React.PropsWithChildren<ImgProps>> = ({
    className,
    src = 'defaultNoData.png',
    alt = 'testImg',
    isStatic = false,
    width,
    height,
    ...restProps
}) => {
    const [imgSrc, setImgSrc] = React.useState(src);

    return (
        <img
            className={className}
            src={isStatic ? imgSrc : BASE_URL + imgSrc}
            alt={alt}
            width={width}
            height={height}
            {...restProps}
            onError={() => {
                setImgSrc('defaultNoData.png');
            }}
        />
    );
};
export { Img };
